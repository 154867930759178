import {
    NotificationActionEnum,
    NotificationConverterT,
    NotificationDescriptorT,
} from 'common/store/notifications/models';
import { logWarning } from 'common/utils/logger';
import { isNonNil } from 'common/utils';
import { TourStatusEnum } from 'common/utils/api/models';

const parseNotificationType = <T extends NotificationActionEnum>(
    notification: any,
): OverwriteT<NotificationDescriptorT[T], { action: T }> => {
    return notification;
};

export const convertNotification: NotificationConverterT = (apiNotification, options) => {
    const baseNotification = {
        isNew: options.isNew,
    } as const;

    switch (apiNotification.action) {
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            return {
                ...parseNotificationType<NotificationActionEnum.SHIPMENT_STATUS_CHANGED>(apiNotification),
                ...baseNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SHIPMENT_MISSED: {
            return {
                ...parseNotificationType<NotificationActionEnum.SHIPMENT_MISSED>(apiNotification),
                ...baseNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SHIPMENT_NO_SHOW: {
            return {
                ...parseNotificationType<NotificationActionEnum.SHIPMENT_NO_SHOW>(apiNotification),
                ...baseNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            return {
                ...parseNotificationType<NotificationActionEnum.ORDER_STATUS_CHANGED>(apiNotification),
                ...baseNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.TOUR_STATUS_CHANGED>(apiNotification);

            const baseTourStatusNotification = {
                ...baseNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            } as const;

            if (
                // @ts-expect-error TODO there is no such status TourStatusEnum.assetsUnassigned
                parsedNotification?.data?.status === TourStatusEnum.assetsUnassigned &&
                // @ts-expect-error wrong api types
                parsedNotification?.data?.runOutOfTime
            ) {
                return {
                    ...baseTourStatusNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS,
                };
            }

            if (
                // @ts-expect-error TODO there is no such status TourStatusEnum.assetsUnassigned
                parsedNotification?.data?.status === TourStatusEnum.assetsUnassigned &&
                // @ts-expect-error wrong api types
                parsedNotification?.data?.runningOutOfTime
            ) {
                return {
                    ...parsedNotification,
                    ...baseTourStatusNotification,
                    action: NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS,
                };
            }

            if (
                // @ts-expect-error TODO there is no such status TourStatusEnum.driverUnassigned
                parsedNotification?.data?.status === TourStatusEnum.driverUnassigned &&
                // @ts-expect-error wrong api types
                parsedNotification?.data?.runOutOfTime
            ) {
                return {
                    ...baseTourStatusNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER,
                };
            }

            if (
                // @ts-expect-error TODO there is no such status TourStatusEnum.driverUnassigned
                parsedNotification?.data?.status === TourStatusEnum.driverUnassigned &&
                // @ts-expect-error wrong api types
                parsedNotification?.data?.runningOutOfTime
            ) {
                return {
                    ...baseTourStatusNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER,
                };
            }

            return {
                ...baseTourStatusNotification,
                ...parsedNotification,
                action: NotificationActionEnum.TOUR_STATUS_CHANGED,
            };
        }
        case NotificationActionEnum.TOUR_RUN_OR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.TOUR_RUN_OR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER>(
                    apiNotification,
                );

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED>(
                    apiNotification,
                );

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.RFQ_ALERT: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.RFQ_ALERT>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_PLACED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_PLACED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_CANCELLED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_CANCELLED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_ASSIGNED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_ASSIGNED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_EXPIRED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_EXPIRED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_ACCEPTED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_BID_ACCEPTED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_BID_EXPIRED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_MISSED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_BID_MISSED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.SPOT_BID_PLACED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.SPOT_BID_PLACED>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.DOCUMENT_EXPIRED>(apiNotification);

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED,
                    relatedIds: [],
                };
            }

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED,
                    relatedIds: [],
                };
            }

            if (apiNotification.subjectType === 'CARRIER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_EXPIRED,
                    relatedIds: [],
                };
            }

            return {
                ...baseNotification,
                ...parsedNotification,
                action: NotificationActionEnum.DOCUMENT_EXPIRED,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.DOCUMENT_COMPLETED>(apiNotification);

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED,
                    relatedIds: [],
                };
            }

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED,
                    relatedIds: [],
                };
            }

            if (apiNotification.subjectType === 'CARRIER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_COMPLETED,
                    relatedIds: [],
                };
            }

            return {
                ...baseNotification,
                ...parsedNotification,
                action: NotificationActionEnum.DOCUMENT_COMPLETED,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.DOCUMENT_EXPIRATION_SOON>(apiNotification);

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON,
                    relatedIds: [],
                };
            }

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON,
                    relatedIds: [],
                };
            }

            if (apiNotification.subjectType === 'CARRIER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON,
                    relatedIds: [],
                };
            }

            return {
                ...baseNotification,
                ...parsedNotification,
                action: NotificationActionEnum.DOCUMENT_EXPIRATION_SOON,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.DOCUMENT_REVOKED>(apiNotification);

            if (apiNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED,
                    relatedIds: [],
                };
            }
            if (apiNotification.subjectType === 'SHIPPER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_REVOKED,
                    relatedIds: [],
                };
            }
            if (apiNotification.subjectType === 'CARRIER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_REVOKED,
                    relatedIds: [],
                };
            }

            return {
                ...baseNotification,
                ...parsedNotification,
                action: NotificationActionEnum.DOCUMENT_REVOKED,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_UPLOAD: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.DOCUMENT_UPLOAD>(apiNotification);
            if (apiNotification.subjectType === 'SHIPPER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED,
                    relatedIds: [],
                };
            }
            if (apiNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD,
                    relatedIds: [],
                };
            }

            return {
                ...baseNotification,
                ...parsedNotification,
                action: NotificationActionEnum.DOCUMENT_UPLOAD,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.DOCUMENT_UPDATED>(apiNotification);
            if (apiNotification.subjectType === 'SHIPPER_CONTRACT_LANE') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED,
                    relatedIds: [],
                };
            }
            if (apiNotification.subjectType === 'SHIPPER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.SHIPPER_CONTRACT_UPDATED,
                    relatedIds: [],
                };
            }
            if (apiNotification.subjectType === 'CARRIER_CONTRACT') {
                return {
                    ...baseNotification,
                    ...parsedNotification,
                    action: NotificationActionEnum.CARRIER_CONTRACT_UPDATED,
                    relatedIds: [],
                };
            }

            return {
                ...baseNotification,
                ...parsedNotification,
                action: NotificationActionEnum.DOCUMENT_UPDATED,
                relatedIds: [],
            };
        }
        case NotificationActionEnum.ERROR_WHEN_CREATE_INVOICE: {
            const parsedNotification =
                parseNotificationType<NotificationActionEnum.ERROR_WHEN_CREATE_INVOICE>(apiNotification);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
        default: {
            const parsedNotification = parseNotificationType<NotificationActionEnum.UNKNOWN_ACTION>(apiNotification);

            logWarning(`Not supported notification: ${apiNotification.action}`);

            return {
                ...baseNotification,
                ...parsedNotification,
                relatedIds: [apiNotification.subjectId].filter(isNonNil),
            };
        }
    }
};
