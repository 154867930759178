import { ApiMultiPointPriceOfferT } from 'common/utils/api/models';
import { PriceOfferT } from '../models';
import { checkIsPastDate, getDateFromISO, parseDateString } from 'common/utils/time';
import { convertToKm } from 'common/utils/distance';
import map from 'lodash/map';
import { isNonNil } from 'common/utils';

export default (apiPriceOffer: ApiMultiPointPriceOfferT): PriceOfferT => {
    const expirationMs = parseDateString(apiPriceOffer.expiration);

    const firstPoint = apiPriceOffer?.points?.[0];
    const lastPoint = apiPriceOffer?.points ? apiPriceOffer.points[apiPriceOffer.points.length - 1] : null;

    return {
        id: apiPriceOffer.id || '',
        dropOffDockingHoursFrom: lastPoint?.from || '',
        dropOffDockingHoursTo: lastPoint?.to || '',
        pickupDockingHoursFrom: firstPoint?.from || '',
        pickupDockingHoursTo: firstPoint?.to || '',
        dropOffDate: getDateFromISO(lastPoint?.from),
        pickUpDate: getDateFromISO(firstPoint?.to),
        isGreenTruck: !!apiPriceOffer.green,
        isUrgentShipment: !!apiPriceOffer.urgent,
        isTeamDrive: !!apiPriceOffer.teamDrive,
        hasLayover: Boolean(apiPriceOffer.layoverSeconds),
        layoverSeconds: apiPriceOffer.layoverSeconds,
        distanceKm: convertToKm(apiPriceOffer.distance) || 0,
        co2: apiPriceOffer.co2 || 0,
        travelSeconds: apiPriceOffer.travelSeconds || 0,
        price: apiPriceOffer.totalPrice,
        expiration: apiPriceOffer.expiration || null,
        expirationMs: parseDateString(apiPriceOffer.expiration),
        isExpired: checkIsPastDate(expirationMs),
        brokerInfo: apiPriceOffer.brokerInfo
            ? {
                  ...apiPriceOffer.brokerInfo,
                  appliedRules: map(
                      apiPriceOffer.brokerInfo?.appliedRules,
                      (rule): NonNullable<PriceOfferT['brokerInfo']>['appliedRules'][0] | null => {
                          if (!rule.valueType) {
                              return null;
                          }

                          return {
                              id: rule.id,
                              group: rule.group,
                              name: rule.name,
                              target: rule.target,
                              value: rule.value,
                              valueType: rule.valueType,
                          };
                      },
                  ).filter(isNonNil),
              }
            : undefined,
        priceComponents: {
            lineHaul: apiPriceOffer.lineHaul,
            toll: apiPriceOffer.tollCost,
            fuel: apiPriceOffer.fuelCost,
            additionalServices: apiPriceOffer.additionalServicesCost,
            tranziitServiceFee: apiPriceOffer.serviceFee,
            urgentOverprice: apiPriceOffer.urgentOverprice,
            greenOverprice: apiPriceOffer.greenOverprice,
            layover: apiPriceOffer.layoverCost,
            green: apiPriceOffer.greenOverprice,
            teamDriveCost: apiPriceOffer.teamDriveCost,
            tollByRoadType: apiPriceOffer.tollByRoadType,
            urgentRate: apiPriceOffer.urgentRate,
            totalRate: apiPriceOffer.totalRate,
            costOfOperation: apiPriceOffer.costOfOperation,
            layoverRate: apiPriceOffer.layoverRate,
            driverCostPerHour: apiPriceOffer.driverCostPerHour,
            teamDriveHours: apiPriceOffer.teamDriveHours,
        },
    };
};
