import RouteIcon from 'common/icons/RouteIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import OptionsIcon from 'common/icons/OptionsIcon';
import TollIcon from 'common/icons/TollIcon';
import FuelIcon from 'common/icons/FuelIcon';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import UrgentIcon from 'common/icons/UrgentIcon';
import EcoIcon from 'common/icons/EcoIcon';
import FeeIcon from 'common/icons/FeeIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import isNumber from 'lodash/isNumber';
import { convertSecToHours } from 'common/utils/time';
import { ALL_TOLL_TYPES, ApiTollByRoadTypeT, CountryCodeT } from 'common/utils/api/models';
import { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import map from 'lodash/map';
import keys from 'lodash/keys';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import { TOLL_MAP_T, TOLL_TOOLTIP_MAP_T } from 'common/components/TollLabel/TollLabel';
import { isNonNil } from 'common/utils';

export const useCommonPriceDetails = () => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            lineHaul: {
                iconNode: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                title: t('common:price-details.line-haul.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.line-haul.tooltip')}
                    </TooltipContent>
                ),
            },
            teamDrive: {
                iconNode: (
                    <TeamDriveIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.team-drive.tooltip')}
                    </TooltipContent>
                ),
                title: t('common:price-details.team-drive.title'),
            },
            getRoadTaxes: (co2: number | null | undefined) => {
                return {
                    iconNode: (
                        <TollIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.gray}
                        />
                    ),
                    title: t('common:price-details.road-taxes.title'),
                    pillLabelNode: isNumber(co2)
                        ? t('common:price-details.road-taxes.co2', {
                              value: co2?.toFixed(2),
                          })
                        : null,
                };
            },
            getRoadTaxesList: (tollByRoadType: ApiTollByRoadTypeT | undefined) => {
                if (!tollByRoadType) {
                    return [];
                }

                const tollList = tollByRoadType
                    ? ALL_TOLL_TYPES.map((tollType): PriceDetailT | null => {
                          const toll = tollByRoadType?.[tollType];
                          if (!toll) {
                              return null;
                          }

                          const { cost, byCountry } = toll;
                          if (!cost && cost !== 0) {
                              return null;
                          }

                          const list = map(keys(byCountry), (countryCode): PriceDetailT => {
                              const countryCost = byCountry?.[countryCode];

                              return {
                                  iconNode: <FlagIcon countryCode={countryCode} />,
                                  title:
                                      !countryCode || countryCode === 'null' ? (
                                          t('common:tolls.unspecified')
                                      ) : (
                                          <AsyncCountryFormatter countryCode={countryCode as CountryCodeT} />
                                      ),
                                  price: countryCost,
                              };
                          });

                          const tollTooltip = TOLL_TOOLTIP_MAP_T[tollType];

                          return {
                              iconNode: null,
                              title: t(TOLL_MAP_T[tollType]),
                              tooltipNode: tollTooltip ? (
                                  <TooltipContent theme={TooltipContentThemeEnum.black} width={150} isCenter>
                                      {t(tollTooltip)}
                                  </TooltipContent>
                              ) : null,
                              price: cost,
                              list,
                          };
                      }).filter(isNonNil)
                    : [];

                return tollList;
            },
            getLineHaulList: ({
                deadheadLineHaulCost,
                payloadLineHaulCost,
            }: {
                deadheadLineHaulCost?: number;
                payloadLineHaulCost?: number;
            }) => {
                const result = [
                    isNumber(deadheadLineHaulCost)
                        ? {
                              iconNode: null,
                              title: t('common:price-details.line-haul-deadhead.title'),
                              price: deadheadLineHaulCost,
                          }
                        : null,
                    isNumber(payloadLineHaulCost)
                        ? {
                              iconNode: null,
                              title: t('common:price-details.line-haul-payload.title'),
                              price: payloadLineHaulCost,
                          }
                        : null,
                ].filter(isNonNil);

                if (!result?.length) {
                    return undefined;
                }

                return result;
            },
            fuelCost: {
                iconNode: <FuelIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />,
                title: t('common:price-details.fuel-price.title'),
            },

            additionalServices: {
                iconNode: (
                    <OptionsIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                ),
                title: t('common:price-details.additional-services.title'),
            },

            getLayoverCost: (layoverSeconds: number | null | undefined) => {
                const layoverHours = isNumber(layoverSeconds) ? convertSecToHours(layoverSeconds) : null;

                return {
                    iconNode: (
                        <TimeWindowIcon
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.gray}
                        />
                    ),
                    tooltipNode: (
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                            {t('common:price-details.layover.tooltip')}
                        </TooltipContent>
                    ),
                    title: t('common:price-details.layover.title'),
                    pillLabelNode: isNumber(layoverHours)
                        ? `${layoverHours} ${t('common:hours', {
                              postProcess: 'interval',
                              count: layoverHours,
                          })}`
                        : null,
                };
            },

            urgentOverprice: {
                iconNode: <UrgentIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.squash} />,
                title: t('common:price-details.express-surcharge.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.express-surcharge.tooltip')}
                    </TooltipContent>
                ),
            },

            greenSurcharge: {
                iconNode: <EcoIcon size={DEFAULT_ICON_SIZE} />,
                title: t('common:price-details.green-surcharge.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.green-surcharge.tooltip')}
                    </TooltipContent>
                ),
            },

            serviceFee: {
                iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:price-details.service-fee.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.service-fee.tooltip')}
                    </TooltipContent>
                ),
            },

            tranziitServiceFee: {
                iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:price-details.tranziit-service-fee.title'),
            },
        } as const;
    }, [t]);
};
